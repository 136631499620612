import request from '@/api/request.js'
// 老师首页-统计
export function statistics (data) {
  return request.post('/teacher/statistics', data)
}
// 首页折线图-班级人数
export function classNunChart (data) {
  return request.post('/teacher/classNunChart', data)
}
// 首页折线图-班级近视人数
export function classMyopiaChart (data) {
  return request.post('/teacher/classMyopiaChart', data)
}
// 老师首页-折线图-本月新增近视/本月新增恢复
export function classMonthNew (data) {
  return request.post('/teacher/classMonthNew', data)
}
// 老师首页-折线图-本月新增近视/本月新增恢复
export function stuData(data) {
  return request.post('/teacher/stuData', data)
}
export function sendNotice(data) {
  return request.post('/teacher/sendNotice', data)
}
export function noticeDel(data) {
  return request.post('/teacher/noticeDel', data)
}
// 老师首页-折线图-累计新增近视/累计新增恢复
export function classTotalNew (data) {
  return request.post('/teacher/classTotalNew', data)
}
// 老师首页-折线图-视力检测
export function classEyepic (data) {
  return request.post('/teacher/classEyepic', data)
}
// 班级学生列表
export function classStudentLis (data) {
  return request.post('/teacher/classStudentList', data)
}
// 班级学生移除
export function classStudentRemove (data) {
  return request.post('/teacher/classStudentRemove', data)
}
// 省市区信息
export function regionInfo (data) {
  return request.post('/teacher/regionInfo',data)
}
// 个人设置-基本设置
export function setUserInfo (data) {
  return request.post('/teacher/setUserInfo', data)
}
// 老师修改密码发送短信
export function setPassSms (data) {
  return request.post('/teacher/setPassSms')
}
// 老师修改密码
export function setPass (data) {
  return request.post('/teacher/setPass', data)
}
// 修改手机号发送手机验证码
export function sendSms (data) {
  return request.post('/adm/sendSms', data)
}
// 修改手机号
export function setTel (data) {
  return request.post('/teacher/setTel', data)
}
// 修改手机号
export function accessList (data) {
  return request.post('/teacher/accessList', data)
}
// 学生图表数据
export function studentChart (data) {
  return request.post('/teacher/studentChart', data)
}
// 家长实名列表
export function authGuaList (data) {
  return request.post('/teacher/authGuaList', data)
}
// 某班某日到校情况
export function classArriveByDate (data) {
  return request.post('/teacher/classArriveByDate', data)
}
// 某班某日到校情况
export function sendScore (data) {
  return request.post('teacher/sendScore', data)
}
export function historyScore (data) {
  return request.post('teacher/historyScore', data)
}
export function scoreDel (data) {
  return request.post('teacher/scoreDel', data)
}
export function noticeList (data) {
  return request.post('teacher/noticeList', data)
}


// 学生到校详情
export function stuArriveInfoByDate (data) {
  return request.post('/teacher/stuArriveInfoByDate', data)
}
// 学生信息修改
export function studentEdit (data) {
  return request.post('/teacher/studentEdit', data)
}
// 新送手机验证码
export function sendSmsNew (data) {
  return request.post('/adm/sendSmsNew', data)
}
// 学生信息和裸眼视力导出
export function luoeyeExcelchu (data) {
  return request.post('/school/luoeyeExcelchu', data)
}
// 学生数据包导出
export function zipchu (data) {
  return request.post('/adm/studentData', data)
}

//****************** 新学校 ******************
// 学生信息和裸眼视力导出
export function gradeClass (data) {
  return request.post('/school/gradeClass', data)
}

// 学生信息
export function teastuinfo (data) {
  return request.post('/teacher/teastuInfo', data)
}

//新增学生
export function addstu (data) {
  return request.post('/teacher/teastuAdd', data)
}

//修改学生
export function editstu (data) {
  return request.post('/teacher/teastuEdit', data)
}

//学生审批记录
export function stuApplyed (data) {
  return request.post('/teacher/studentApplyApprove', data)
}

//家长审批记录
export function guaApplyed (data) {
  return request.post('/teacher/parentApplyApprove', data)
}
//家长申请列表
export function guaApplyelist (data) {
  return request.post('/teacher/parentApply', data)
}
//家长申请状态修改
export function guaApplyeedit (data) {
  return request.post('/teacher/parentApplyEdit', data)
}
//学生申请列表       teacher/studentApplyApprove
export function stuApplyelist (data) {
  return request.post('/teacher/parentApplyEdit', data)
}
//学生申请状态修改   teacher/studentApplyEdit
export function stuApplyeedit (data) {
  return request.post('/teacher/studentApplyEdit', data)
}

// 学校创建校区
export function schoolCreateCampus (data) {
  return request.post('/school/schoolCreateCampus', data)
}
// 学校查询校区
export function schoolSearchCampus () {
  return request.post('/school/schoolSearchCampus')
}
// 学校切换校区
export function schoolChangeCampus (data) {
  return request.post('/school/schoolChangeCampus', data)
}
// 发布文章
export function pushArticle (data) {
  return request.post('/teacher/pushArticle', data)
}
// 发布文章
export function handStu (data) {
  return request.post('/teacher/handStu', data)
}
// 发布文章
export function motionInfo (data) {
  return request.post('/teacher/motionInfo', data)
}
// 发布文章
export function handStuOne (data) {
  return request.post('/teacher/handStuOne', data)
}
// 发布文章
export function motionInfoOne (data) {
  return request.post('/teacher/motionInfoOne', data)
}